import React, { useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-extended/lib/bootstrap-extended.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";


import "./App.css";
import $ from "jquery";

import { Route, Routes, useLocation } from "react-router-dom";

import NoInternet from "./Components/NoInternet";

import NotFound from "./Components/admin/includes/NotFound";

import axios from "axios";
import Error429 from "./Components/admin/includes/Error429";
import { ToastContainer } from "react-toastify";

import RegisterForm from "./Components/RegisterForm";
import TestOurTech_thankyou from "./Components/TestOurTech_thankyou";
import PrivacyPolicy from "./Components/PrivacyPolicy";

function App() {
  const locat = useLocation();

  useEffect(() => {
    if (locat.pathname.match(/\/{2,}/)) {
      window.location.href = "/";
    }
    // Add a global error handler for Axios requests
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 429) {
          // Redirect to the error page if the response status is 429
          window.location.href = "/error-429"; // Replace '/error' with your actual error page route
        }
        // Handle other error scenarios if needed
        return Promise.reject(error);
      }
    );

    // Clean up the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    const backToTop = () => {
      return (
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          600
        ),
        !1
      );
    };
    backToTop();

    // eslint-disable-next-line
  }, [locat.pathname]);

  return (
    <NoInternet>
      <ToastContainer />

      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/" element={<RegisterForm />} />
        <Route
          path="/register/registration-thankyou"
          element={<TestOurTech_thankyou />}
        />

        <Route path="/error-429" element={<Error429 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {locat.pathname.match(/\/{2,}/) ? (
        <div className="fix-white"></div>
      ) : null}
    </NoInternet>
  );
}

export default App;
