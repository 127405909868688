import { React, useState, useEffect } from "react";

import logo from "../Images/logo-new.png";

import thankYou from "../Images/thank-you.png";

import { Link, Navigate, useNavigate } from "react-router-dom";

import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";

function TestOurTech_thankyou(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Thank You";

  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const navigate = useNavigate();

  useEffect(() => {
    function timeout() {
      setTimeout(function () {
        localStorage.setItem("returnpage", false);
        localStorage.removeItem("RegisterForm");
        localStorage.removeItem("TestOurTech_thankyou");
        window.location.replace("/");
        return false;
      }, signoutTime);
    }

    timeout();
  }, [signoutTime]);

  //FIXME
  const isRegister = localStorage.getItem("isRegister");
  if (
    isRegister === "false" ||
    isRegister === null ||
    isRegister === undefined
  ) {
    return <Navigate to="/" />;
  }
  var registerform = secureLocalStorage.getItem("registerform");
  var RegisterObject = registerform ? JSON.parse(registerform) : false;
  if (typeof RegisterObject === "boolean") {
    return <Navigate to="/" />;
  } else {
    // let showroom = RegisterObject.showroom ? RegisterObject.showroom : false;
  }
  const myDestory = (e) => {
    e.preventDefault();
    localStorage.removeItem("return_page", false);
    localStorage.removeItem("isRegister");
    secureLocalStorage.removeItem("registerform");
    window.location.replace("/");
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="redem-home">
        <div className="voucher-section">
          <div className="voucher-entry">
            <img src={logo} alt="logo..." className="logo-redem" />
            <div className="read-carefully thank">
              Congratulations on your registration.
            </div>
            <h4 className="thank-head">
              {process.env.REACT_APP_THANKYOUTITLE}
            </h4>
            <img src={thankYou} alt="" className="img-fluid thank-img mb-4" />
          </div>
        </div>
        <div className="infor-section thankyou">
          <ul>
            <li className="active">
              <div className="info-text">
                <h6>{process.env.REACT_APP_THANKYOUTITLE} </h6>
                <span>
                  You will receive an email with your unique code from our
                  fulfillment center.
                </span>
              </div>
            </li>

            <li>
              <div className="info-text">
                Once you receive your email you will be given instructions on
                redeeming your Reward Card.
              </div>
            </li>
            <li>
              <div className="info-text">
                For further information please call{" "}
                <a href={"tel:" + process.env.REACT_APP_DESC_CONTACTUS + '"'}>
                  <strong>{process.env.REACT_APP_DESC_CONTACTUS}</strong>
                </a>{" "}
                or email us at{" "}
                <a
                  href={
                    "mailto:" + process.env.REACT_APP_DESC_CUSTOMERCARE + '"'
                  }
                >
                  <strong>{process.env.REACT_APP_DESC_CUSTOMERCARE}</strong>
                </a>
                <span>
                  and one of our customer service representatives will be happy
                  to assist you.
                </span>
              </div>
            </li>
            <li>
              <div className="info-text buton-li">
                <Link to="/register" className="back-certi" onClick={myDestory}>
                  Go Back
                </Link>
              </div>
            </li>

            <div className="new-footer">
              <p>
                © Copyright {new Date().getFullYear()}. All rights reserved.{" "}
                {process.env.REACT_APP_API_SITE_TITLE}.
              </p>
            </div>
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>

      {/* <Footer2 /> */}
    </>
  );
}
export default TestOurTech_thankyou;
